<template>

    <transition name="blur-background">
        <blur v-if="show">
        </blur>
    </transition>

    <transition name="modal-small">
        <div class="fixed z-50 inset-0 overflow-y-auto ccss--modal-accounts fixed-modal-wrapper" v-if="show">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0">
                <div
                    class="modal inline-block align-bottom rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full radius-anchor"
                    role="dialog" aria-modal="true" aria-labelledby="modal-headline"
                >
                    <div class="">
                        <div class="modal-close">
                            <a @click="close()" class="modal-close" aria-label="Close">
                                <svg class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                          d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </a>
                        </div>
                        <div class="sm:flex sm:items-start">
                            <div class="text-center sm:text-left py-8 px-4  sm:rounded-sm pb-0">
                                <h3 class="text-lg leading-6 font-bold text-2xl text-gray-900" id="modal-headline">
                                    {{ $t('modal.selectaccount.title') }}
                                </h3>
                            </div>
                        </div>

                        <div class="mt-5 sm:mt-6">
                            <ul class="space-y-3 p-4">
                                <li v-for="(account, index) in accounts" :key="index" @click="setAccount(account)"
                                    class="cursor-pointer group relative btn w-full"
                                >

                                    {{ account.attributes.name }}

                                </li>
                                <li @click="clearAccount()"
                                    class="cursor-pointer group relative rounded-sm shadow-sm cursor-pointer focus:outline-none focus:shadow-outline-blue"
                                >
                                    <button class="btn btn-primary w-full">{{ $t('modal.selectaccount.continuenoaccount') }}</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import * as _api from '../lib/api';
import * as _transaction from '../lib/transaction';
import * as _state from '../lib/state';

import Blur from "./Blur";

export default {
    components: {
      Blur,
    },
    data () {
        return {
            show: false,
            accounts: [],
        };
    },
    methods: {
        async open () {
            if (this.authenticated) {
                this.show = true;

                this.accounts = await this.getAccounts();
            }
        },
        close () {
            this.show = false;
        },

        async getAccounts () {
            return await _api.get('customer/accounts', {}, false);
        },

        setAccount (account) {
            _transaction.setAccount({
                id: account.id,
                name: account.attributes.name,
            });

            if (account.relationships.discount) {
                _transaction.setDiscount({
                    id: account.relationships.discount.id,
                    description: account.relationships.discount.attributes.description,
                    type: account.relationships.discount.attributes.type,
                    value: account.relationships.discount.attributes.value,
                });
            } else {
                _transaction.setDiscount(false);
            }

            _transaction.clearDiscount();
            _transaction.applyDiscount();

            this.$emit('closeMobileMenu');

            this.close();
        },

        clearAccount () {
            _transaction.setAccount(false);

            this.close();
        },
    },
    computed: {

        authenticated () {
            let user = _state.get('user/getUser');

            return user.authenticated;
        },

    },
};
</script>
