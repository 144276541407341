import router from '../router';
import * as _api from './api';
import * as _state from './state';
import * as _transaction from './transaction';
import * as _applicationState from './application-state';
import * as _notification from './notification';
import moment from 'moment';

const init = async table_id => {
    const response = await _api.get('tables', table_id);

    if (!response) {
        _notification.set('notification.table.not-found', 'error');
        router.push({ name: 'table.landing' });
        return;
    }

    _state.set('table/setTable', response);
    _state.set('table/setAutoClose', true);
    _transaction.setTable({
        id: response.id,
        name: response.attributes.name,
    });
};

const closeTable = async () => {
    _state.set('table/setTable', false);
    _state.set('table/setAutoClose', false);
    _transaction.clear();
    _notification.set('notification.table.closed', 'success');
    window.location.replace('/tafel/landing');
};

const refresh = async () => {
    const table = get();

    const response = await _api.get('tables', table.id);

    _state.set('table/setTable', response);
};

const constructHourlyRate = async (hours) => {
    const table = get();


    let fetchedTable = await _api.get('/tables/' + table.id);
    const area_id = fetchedTable.attributes.area_id;

    console.log(moment().format('YYYY-MM-DD'));

    const timeslot = await _api.post('timeslots', {
        date: moment().format('YYYY-MM-DD'),
        function: 'reservation',
        area_id: area_id,
    });

    let item = timeslot.attributes.reservation_item;


    console.log('fetchedTable', fetchedTable);
    // let item = fetchedTable.relationships.hourly_rate_item;

    if (!item) {
        return false;
    }

    let hoursDifference = hours;
    if (!hours) {
        let startDate = new Date(fetchedTable.timestamp);
        let endDate   = new Date(fetchedTable.attributes.activated_at);
        let secondsDifference = Math.abs(endDate.getTime() - startDate.getTime()) / 1000;
        hoursDifference = secondsDifference / 3600;
    }


    const returnData = {
        "hourlyRate": item.attributes.price,
        "hoursDifference" : hoursDifference,
        "totalPrice" : calculateTotalPrice(item.attributes.price, hoursDifference, timeslot),
    }

    return returnData;
};

const sortItemsByQuantity = (items) => {
    return items.sort((a, b) => a.quantity - b.quantity);
}

const calculateTotalPrice = (price, hours, timeslot) => {
    // if no staffelprijzen return price * hours
    if (timeslot.attributes.reservation_items_multiple_timeslots.length == 0) {
        return price * hours;
    }

    //sort items by quantity
    const items = sortItemsByQuantity(timeslot.attributes.reservation_items_multiple_timeslots);

    // check which quantity is the last 'reached' one, comparing hours with quantity. 
    // so quanity [1,3,5,7] with hours 6 will return 5, the last one passed
    let lastQuantityItem = null;
    items.forEach(function(item) {
        if (hours >= item.quantity) {
            lastQuantityItem = item;
        } 
    });

    //if none yet reached just return price * hours
    if (lastQuantityItem == null) {
        return price * hours;
    }

    return lastQuantityItem.item.attributes.price;
}

const getBasketTimeData = async () => {
    const originalTimer = 5 * 60;
    let timer = originalTimer;
    let expired = false;

    let startTime = _applicationState.getBasketStartTime();

    //compare startTime with now, indicating how many seconds left to end transaction
    const startTimeUts = Math.floor(new Date(startTime).getTime() / 1000);
    const now = new Date();
    const nowUts = Math.floor(now.getTime() / 1000);
    const timeDifference = nowUts - startTimeUts;
    timer = timer - timeDifference;

    //time has expired; reset values
    if (timeDifference > originalTimer) {
        expired = true; 
        startTime = new Date();
        _applicationState.setBasketStartTime(startTime);
        _applicationState.setHoursDifference(null);

        timer = originalTimer;
    }

    return {
        timer, expired
    }
};

// getters
const get = () => {
    return _state.get('table/getTable');
};

const initAutoClose = () => {
    if (_state.get('table/getTable')) {
        return _state.get('table/getAutoClose');
    }
    return false;
};

export { init, initAutoClose, closeTable, get, refresh, constructHourlyRate, getBasketTimeData };

