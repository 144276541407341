import * as _api from './api';
import * as _log from './log';
import * as _transaction from '../lib/transaction';
import * as _state from '../lib/state';
import * as _notification from '../lib/notification';
import * as _applicationState from '../lib/application-state';

// set the logger
const logger = _log.get('AUTHENTICATION');

const login = async (email, password) => {
    logger.log('login for email', email)();

    let response = await _api.post('auth/login', {
        email: email,
        password: password,
    });
    logger.log('login response', response)();

    if (response.result !== 'success') {
        _state.set('token/setToken', false);
        _state.set('user/setUser', {});

        return false;
    }

    // set token
    _state.set('token/setToken', response.data.token);

    // set user
    _state.set('user/setUser', {
        authenticated: true,
        username: response.data.username,
        hasAccounts: response.data.has_accounts,
        hasWallets: response.data.has_wallets,
    });

    if (_applicationState.isAuthenticationRequired()) {
        window.location.reload();
    }

    return true;
};

const logout = async () => {
    logger.log('logout')();

    // await _api.post('auth/logout');

    _state.set('token/setToken', false);
    _state.set('user/setUser', {});

    _transaction.setAccount(false);
    _transaction.clearDiscount();

    _notification.set('notification.logout.succesful', 'success');

    if (_applicationState.isAuthenticationRequired()) {
        window.location.reload();
    }

    return true;
};

const register = async (data) => {
    logger.log('register', data)();

    const response = await _api.post('customer/register', data);

    logger.log('register response', response)();

    if (response.result !== 'success') {
        _state.set('token/setToken', false);
        _state.set('user/setUser', {});

        _notification.set(response.message, 'error');

        return false;
    }

    // set token
    _state.set('token/setToken', response.data.token);

    // set user
    _state.set('user/setUser', {
        authenticated: true,
        username: response.data.username,
        hasAccounts: response.data.has_accounts,
        hasWallets: response.data.has_wallets,
    });

    _notification.set('Registered successfully', 'success');

    if (_applicationState.isAuthenticationRequired()) {
        // window.location.reload()
    }

    return true;
};

export { login, logout, register };
