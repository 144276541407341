import axios from 'axios';
import * as _auth from './auth';
import * as _state from './state';
import * as _log from './log';

// set the logger
const logger = _log.get('API');

let _token = false;

const endpoints = {
    // active
    active: () => {
        return '/active';
    },

    // areas
    'areas/busy': () => {
        return '/areas/busy';
    },

    // items
    items: (data) => {
        let endPoint = '/items';

        if (data && data.elockers_active) {
            endPoint += '?elockers_active=true';
        }
        return endPoint;
    },

    'items/check-availability': () => {
        return '/items/check-availability';
    },

    // categories
    category: () => {
        return '/category';
    },
    categories: () => {
        return '/categories';
    },

    // reservations
    reservations: () => {
        return '/reservations';
    },

    'reservations/areas': () => {
        return '/reservations/areas';
    },

    'reservations/areas/tables': () => {
        return `/reservations/areas/tables`;
    },

    'cashfunctions/wallet': () => {
        return '/cashfunctions/wallet';
    },

    // settings
    config: () => {
        return '/config';
    },
    'settings/reservation': () => {
        return '/settings/reservation';
    },

    // login
    'auth/login': () => {
        return '/auth/login';
    },

    // customer
    customer: () => {
        return '/customer';
    },

    'customer/password': () => {
        return '/customer/password';
    },

    'customer/activate': () => {
        return '/customer/activate';
    },

    'customer/transactions': () => {
        return '/customer/transactions';
    },

    'customer/transactions/:id': (id) => {
        return `/customer/transactions/${id}`;
    },

    'customer/register': () => {
        return '/customer/register';
    },

    'customer/delete-profile': () => {
        return '/customer/delete-profile';
    },

    'customer/accounts': () => {
        return '/customer/accounts';
    },

    'customer/wallets': () => {
        return '/customer/wallets';
    },

    'customer/wallettokens/block': () => {
        return '/customer/wallettokens/block';
    },

    'customer/wallettokens/connect': () => {
        return '/customer/wallettokens/connect';
    },

    // wallets
    'wallettokens/connect': () => {
        return '/wallettokens/connect';
    },

    // address
    address: () => {
        // todo: rename route
        return '/get-address';
    },
    'address/zipcode-available': () => {
        // todo: rename route
        return '/delivery/zipcode-check';
    },

    // paymenttypes
    paymenttypes: (account_id) => {
        if (account_id) {
            return `/paymenttypes?account_id=${account_id}`;
        }
        return '/paymenttypes';
    },

    //timeslots
    timeslots: () => {
        // if (data) {
        //     return `/timeslots/${data}`;
        // }
        return '/timeslots';
    },

    // locations
    locations: (slug) => {
        if (slug) {
            return `/locations/${slug}`;
        }

        return '/locations';
    },

    // tables
    tables: (id) => {
        if (id) {
            return `/tables/${id}`;
        }

        return '/tables';
    },

    'locations/:location_slug/tables': (slug) => {
        return `/locations/${slug}/tables`;
    },

    'location/areas/:area_id/tables-selection': (areaId) => {
        return `/location/areas/${areaId}/tables-selection`;
    },

    'table/activate': () => {
        return '/table/activate';
    },

    'table/set-customer-info': () => {
        return '/table/set-customer-info';
    },

    'tableline/remove': () => {
        return '/tableline/remove';
    },

    // transactions
    transactions: () => {
        return '/transactions';
    },
    'transactions/table': () => {
        return '/transactions/table';
    },
    'transaction/:transaction_id/quickpickup': (transactionId) => {
        return `/transaction/${transactionId}/quickpickup`;
    },

    // todo: variable identifier in endpoint
    'transactions/{transaction_id}': () => {
        return '/transactions/{transaction_id}';
    },

    // payments
    payments: () => {
        return `/payments`;
    },
    'payments/cancel': () => {
        return `/payments/cancel`;
    },
    'payments/:payment_id/status': (id) => {
        return `/payments/${id}/status`;
    },

    // adyen - payments
    'adyen/payments/methods': () => {
        return '/payments/methods';
    },
    'adyen/payments/session': () => {
        return '/payments/session';
    },
    'adyen/payments/data': () => {
        return '/payments/data';
    },
    'adyen/payments/donation': () => {
        return '/payments/donation';
    },

    // elockers
    'elockers/events': (data) => {
        let endPoint = '/integration/elockers/events?sort=date&filter[active]=true&filter[active_shop]=true';

        if (data.event) {
            endPoint += `&event=${data.event}`;
        }

        if (data.location) {
            endPoint += `&location=${data.location}`;
        }

        if (data.area) {
            endPoint += `&area=${data.area}`;
        }

        if (data.slug) {
            endPoint += `&slug=${data.slug}`;
        }
        return endPoint;
    },
    'elockers/coinlock/{locker_uuid}': (locker_uuid) => {
        return `/integration/elockers/coinlock/${locker_uuid}`;
    },
    'elockers/tickets/reserve': () => {
        return '/integration/elockers/tickets/reserve';
    },
    'elockers/tickets/release': () => {
        return '/integration/elockers/tickets/release';
    },
    'elockers/locker/maintenance': () => {
        return '/integration/elockers/locker/maintenance';
    },

    /* -- post requests -- */

    // login
    login: () => {
        return '/auth/login';
    },

    //forgot password
    'password/forget': () => {
        return '/auth/password/forget';
    },

    'password/reset': () => {
        return '/auth/password/reset';
    },

    // 'reset-password': () => {
    //     return '/auth/reset-password';=
    // },

    'transaction/:transaction_id/quickpickup/process': (transactionId) => {
        return `/transaction/${transactionId}/quickpickup/process`;
    },

    'items/search/barcode': () => {
        return '/items/search/barcode';
    },

    // 'items/:item_id': (itemId) => {
    //     return `/items/${itemId}`;
    // },

    // remove?
    'wallet/token/activate': () => {
        return '/wallet/token/activate';
    },

    'wallet/token/check': () => {
        return '/wallet/token/check';
    },

    'voucher/amount/reserve': () => {
        return '/voucher/amount/reserve';
    },

    // 'get-address': () => {
    //     return '/get-address';
    // },

    'delivery/zipcode-check': () => {
        return '/delivery/zipcode-check';
    },

    'location/area/:area_id/tables-selection': (areaId) => {
        return `/location/area/${areaId}/tables-selection`;
    },

    tabletransactions: () => {
        return '/tabletransactions';
    },
};

const getToken = async (force = false) => {
    let _token = force ? false : _state.get('token/getToken');

    if (!_token) {
        return false;
    } else {
        return _token;
    }
};

const get = async (endPointId, params, anonymous = true) => {
    let endPoint;
    if (!anonymous) _token = await getToken();
    if (!anonymous && !_token) {
        logger.error('API getToken FAILED prior to GET {}', endPointId)();
        return false;
    }
    try {
        if (endPointId.substr(0, 1) !== '/') {
            if (!endpoints[endPointId]) return [];
            endPoint = endpoints[endPointId](params);
            if (!endPoint) {
                let err = new Error('Endpoint ${endPointId} not found');
                err.status = 404;
                err.message = 'Endpoint ${endPointId} not found';
                throw err;
            }
        } else {
            endPoint = endPointId;
        }
        let response = await axios
            .get(endPoint, {
                //prams: params,
                // timeout: 2000,
                headers: {
                    'Content-Type': 'application/json',
                    //'Accept': 'application/json',
                    Authorization: anonymous ? '' : 'Bearer ' + _token,
                    // 'Source': 'webshop',
                    // 'Domain': 'window.location.host',
                    // 'LocalizationTODO': 'nl',
                },
            })
            .then((response) => {
                // solves the error in api response
                if (typeof response.data.data !== 'object') {
                    return { data: response.data };
                }

                return response.data.data;
            });

        return 'data' in response ? response.data : response;
    } catch (error) {
        logger.error('API GET {} ERROR: {}', endPoint, error)();

        if (error.response.status === 401) {
            _auth.logout();

            // todo: notification
            // window.location = '/login';
        }
        return false;
    }
};

const post = async (endPointId, data, params, anonymous = true) => {
    let endPoint;
    if (!anonymous) _token = await getToken();

    if (!anonymous && !_token) {
        logger.error('API getToken FAILED prior to GET {}', endPointId)();
        return false;
    }
    try {
        if (endPointId.substr(0, 1) !== '/') {
            if (!endpoints[endPointId]) return [];
            endPoint = endpoints[endPointId](params);
            if (!endPoint) {
                let err = new Error('Endpoint ${endPointId} not found');
                err.status = 404;
                err.message = 'Endpoint ${endPointId} not found';
                throw err;
            }
        } else {
            endPoint = endPointId;
        }
        let response = await axios
            .post(endPoint, data, {
                // timeout: 2000,
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                    Authorization: anonymous ? '' : 'Bearer ' + _token,
                },
            })
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                logger.error('API GET {} ERROR: {}', endPoint, error)();

                if (error.response.status === 401) {
                    _auth.logout();
                    // todo: notification
                }

                return false;
            });

        return response;
        // return 'data' in response ? response.data : response;
    } catch (error) {
        logger.error('API GET {} ERROR: {}', endPoint, error)();
        if (error.response.status === 401) {
            _auth.logout();
        }
        return false;
    }
};

export { get, post, getToken };
