import * as _api from './api';
import * as _log from './log';
import * as _state from './state';
import * as _applicationState from './application-state';

// set the logger
const logger = _log.get('LOCATION');

const init = async (slug = false) => {
    logger.log('init')();

    if (!slug) {
        set('location/setLocation', {
            id: '',
            theme_id: null,
            name: '',
            slug: '',
            image: null,
            open: false,
            delivery: {},
            quickorder: {},
            lockers: {},
            streetname: '',
            housenumber: '',
            zipcode: '',
            city: '',
            country: '',
            email: '',
            phone_number: '',
            social_media: null,
            openingstimes: false,
            today: false,
            confirmation_text: false,
            delivery_fee: false,
            delivery_default_items: [],
            takeaway_default_items: [],
            quickorder_default_items: [],
            tables_default_items: [],
        });
        return;
    }

    // get the location
    const response = await _api.get('locations', slug);
    console.log('--->', response);
    set({
        id: response.id,
        name: response.attributes.name,
        slug: response.attributes.slug,
        open: response.attributes.open,
        image: response.attributes.image,
        delivery: response.attributes.delivery,
        takeaway: response.attributes.takeaway,
        openingstimes: response.attributes.openingstimes,
        quickorder: response.attributes.quickorder,
        lockers: response.attributes.lockers,
        streetname: response.attributes.streetname,
        housenumber: response.attributes.housenumber,
        zipcode: response.attributes.zipcode,
        city: response.attributes.city,
        country: response.attributes.country,
        email: response.attributes.email,
        phone_number: response.attributes.phone_number,
        social_media: response.attributes.social_media,
        wallet: response.attributes.wallet,
        today: {
            open: response.attributes.today_open,
            openingstimes: response.attributes.today_openingstimes,
        },
        confirmation_text: response.attributes.webshop,
        delivery_fee: response.attributes.delivery ? response.attributes.delivery : false,
        delivery_default_items: response.attributes.functions.delivery_default_items ? response.attributes.functions.delivery_default_items : false,
        takeaway_default_items: response.attributes.functions.takeaway_default_items ? response.attributes.functions.takeaway_default_items : false,
        quickorder_default_items: response.attributes.functions.quickorder_default_items
            ? response.attributes.functions.quickorder_default_items
            : false,
        tables_default_items: response.attributes.functions.tables_default_items ? response.attributes.functions.tables_default_items : false,
        elockers_default_items: response.attributes.functions.elockers_default_items ? response.attributes.functions.elockers_default_items : false,
    });
    let functions = {
        delivery: response.attributes.functions.delivery ? response.attributes.functions.delivery : false,
        takeaway: response.attributes.functions.takeaway ? response.attributes.functions.takeaway : false,
        quickorder: response.attributes.functions.quickorder ? response.attributes.functions.quickorder : false,
        tables: response.attributes.functions.tables ? response.attributes.functions.tables : false,
        menu: response.attributes.functions.menu ? response.attributes.functions.menu : false,
        elockers: response.attributes.functions.elockers ? response.attributes.functions.elockers : false,
        reservation: response.attributes.functions.reservation ? response.attributes.functions.reservation : false,
        visitors: response.attributes.functions.visitors ? response.attributes.functions.visitors : false,
        wallet: response.attributes.functions.wallet ? response.attributes.functions.wallet : false,
    };

    _applicationState.setFunctions(functions);
};

const isOpen = async (refresh = false) => {
    const location = get();

    if (refresh === false) {
        return location.open;
    }

    let response = await _api.get('locations', location.slug);

    return response.attributes.open;
};

const get = () => {
    return _state.get('location/getLocation');
};
// todo: today_openingstimes, today_open (see navigation)
const set = (location) => {
    _state.set('location/setLocation', {
        id: location.id,
        name: location.name,
        slug: location.slug ?? false,
        image: location.image,
        open: location.open,
        delivery: location.delivery,
        quickorder: location.quickorder,
        lockers: location.lockers,
        takeaway: location.takeaway,
        streetname: location.streetname,
        housenumber: location.housenumber,
        zipcode: location.zipcode,
        city: location.city,
        country: location.country,
        email: location.email,
        phone_number: location.phone_number,
        social_media: location.social_media,
        wallet: location.wallet,
        openingstimes: location.openingstimes,
        today: location.today,
        confirmation_text: location.confirmation_text,
        delivery_fee: location.delivery_fee,
        delivery_default_items: location.delivery_default_items,
        takeaway_default_items: location.takeaway_default_items,
        quickorder_default_items: location.quickorder_default_items,
        tables_default_items: location.tables_default_items,
        elockers_default_items: location.elockers_default_items,
    });
};

export { init, isOpen, get, set };
